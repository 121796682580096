import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Form,
    Col,
    Row,
    Input,
    Select,
    notification,
    InputNumber,
    DatePicker
} from 'antd'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'

interface CreateDataProps {
    isOpenCreateData: boolean
    closeCreateData: () => void
    onloadData: () => void
    safeList: any
}
interface CreateDataInsertProps {
    title: string
    safe_id: number
    amount: number
    date_time: string
    kind: string
    remark: string
}

const CreateData: FC<CreateDataProps> = ({ isOpenCreateData, closeCreateData, onloadData, safeList }) => {
    const nameTitle = "เพิ่มเงินเข้า-เงินออก"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")

    const insertData = async (values: CreateDataInsertProps) => {
        const createResponse: any = await sendAPIWithJWT({
            path: "/reports",
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (createResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: createResponse.data?.message
            });
            setBtnLoading(false)
            closeCreateData()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: createResponse.message ? createResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = (kind: string) => {
        form.validateFields()
            .then((values: CreateDataInsertProps) => {
                setBtnLoading(true)
                values.kind = kind;
                // values.amount = Number(values.amount);
                values.date_time = moment(values.date_time).format('YYYY-MM-DD');
                insertData(values)

            })
            .catch((errorInfo) => { });
    };

    const getSafeOption = Object.values(safeList).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    useEffect(() => {
        if (form && isOpenCreateData) {
            form.resetFields();
            // form.setFieldsValue({ status: 'enable' })
        }
    }, [form, isOpenCreateData]);

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeCreateData}
                visible={isOpenCreateData}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    form={form}
                    name="createForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="safe_id"
                                label="ตู้เซฟ"
                                rules={[{ required: false, message: 'กรุณาเลือกตู้เซฟ' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกตู้เซฟ"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getSafeOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="ชื่อรายการ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อรายการ' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อรายการ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label="จำนวนเงิน"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวนเงิน' },
                                    { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                            >
                                {/* <Input placeholder="จำนวนเงิน" /> */}
                                <InputNumber
                                    placeholder="จำนวนเงิน"
                                    inputMode="numeric"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0,2}$)/g, ".")}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    controls={false}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9.,\b]+$/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="date_time"
                                label="วันที่ทำรายการ"
                                rules={[
                                    { required: true, message: 'กรุณากรอกวันที่ทำรายการ' }
                                ]}
                                hasFeedback
                            >
                                <DatePicker placeholder="วันที่ทำรายการ" className="date-picker-employee" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                                hasFeedback
                            >
                                <Input.TextArea placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit("increase")}><i className="far fa-plus me-2"></i> เพิ่มเงิน</button>
                        <button type="submit" className="btn btn-info" disabled={btnLoading} onClick={() => handleFormSubmit("decrease")}><i className="far fa-minus me-2"></i> ถอนเงิน</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeCreateData()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default CreateData
