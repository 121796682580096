import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from './../../assets/img/logo.png'
import './../../assets/scss/fontawesome.scss'
import './../../assets/scss/sidebar.scss'
// import useAuth from '../../utils/Auth'
// import ls from 'localstorage-slim'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Sidebar = () => {
  const { allows } = useSelector((state: ApplicationState) => state.auth)
  const currentLocation = useLocation().pathname;
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-body">
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto a-logo">
            <img src={logo} className="sidebar-logo" alt="logo" />
          </a>
          <div className="sidebar-menu">
            <ul className="nav nav-pills flex-column">
              {Object.values(allows).find((item: any) => item === "DASHBOARD_PAGE") &&
                <li className="nav-item">
                  <Link to="/dashboard"
                    className={"nav-link link-dark" + (currentLocation === '/dashboard' ? ' active' : '')}
                  >
                    <i className="fas fa-tachometer-fast"></i>
                    <span>ภาพรวม</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "REPORT_PAGE") &&
                <li>
                  <Link to="/reports"
                    className={"nav-link link-dark" + (currentLocation === '/reports' ? ' active' : '')}
                  >
                    <i className="fas fa-money-bill-wave"></i>
                    <span>เงินเข้า-เงินออก</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "SAFE_PAGE") &&
                <li>
                  <Link to="/safes"
                    className={"nav-link link-dark" + (currentLocation === '/safes' ? ' active' : '')}
                  >
                    <i className="fas fa-university"></i>
                    <span>ตู้เซฟ</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "USER_PAGE") &&
                <li>
                  <Link to="/users"
                    className={"nav-link link-dark" + (currentLocation === '/users' ? ' active' : '')}
                  >
                    <i className="fas fa-user"></i>
                    <span>ผู้ใช้งาน</span>
                  </Link>
                </li>
              }
              {Object.values(allows).find((item: any) => item === "LOGS_PAGE") &&
                <li>
                  <Link to="/system-logs"
                    className={"nav-link link-dark" + (currentLocation === '/system-logs' ? ' active' : '')}
                  >
                    <i className="fas fa-clock"></i>
                    <span>ประวัติการใช้งาน</span>
                  </Link>
                </li>
              }
            </ul>
          </div>
          <div className="nav-pills sidebar-footer">
            <a href="/logout" className="nav-link link-dark">
              <i className="fas fa-sign-out"></i>
              <span>ออกจากระบบ</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
