import React, { FC } from 'react'
import {
    Drawer,
    Divider,
    Descriptions,
} from 'antd';
import styled from 'styled-components'

interface ViewDataProps {
    listData: any
    closeViewData: () => void
    isOpenViewData: boolean
}

const ViewData: FC<ViewDataProps> = ({ closeViewData, isOpenViewData, listData }) => {
    const nameTitle = "ข้อมูลรายการเงินเข้า-เงินออก"

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeViewData}
                visible={isOpenViewData}
                bodyStyle={{ paddingBottom: 80 }}
            >
                {listData && (
                    <>
                        <Descriptions title={nameTitle} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                            <Descriptions.Item label="ตู้เซฟ">{listData.safe_name}</Descriptions.Item>
                            <Descriptions.Item label="ชื่อรายการ">{listData.title}</Descriptions.Item>
                            {/* <Descriptions.Item label="จำนวนเงิน">{numberWithCommas(listData.amount)}</Descriptions.Item> */}
                            <Descriptions.Item label="จำนวนเงิน">
                                <TextNoWrap>
                                    {listData.kind === "decrease" ?
                                        <div style={{ color: "#ff0000" }}>฿ -{numberWithCommas(listData.amount)}</div>
                                        :
                                        <div>฿ {numberWithCommas(listData.amount)}</div>
                                    }
                                </TextNoWrap>
                            </Descriptions.Item>
                            <Descriptions.Item label="วันที่ทำรายการ">{listData.date_time.replace(" 00:00:00", "")}</Descriptions.Item>
                            <Descriptions.Item label="หมายเหตุ">{listData.remark}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                            <Descriptions.Item label="วันที่สร้าง">{listData.createdAt}</Descriptions.Item>
                            <Descriptions.Item label="สร้างโดย">{listData.created_user_name}</Descriptions.Item>
                        </Descriptions>
                    </>
                )}
            </Drawer>
        </>
    )
}
export default ViewData


const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
