import React, { Fragment, useEffect, useState } from 'react'
import SendAPI from '../../utils/SendAPI';
import LogsTable from '../../components/Logs/Table'
import moment from 'moment';

const Logs = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataLogs, setDataLogs] = useState<any>([])

    const [dateStartSearch, setDateStartSearch] = useState<any>()
    const [dateEndSearch, setDateEndSearch] = useState<any>()

    const getLogs = async (dateStart: any, dateEnd: any) => {
        let date = ""
        if (dateStart && dateEnd) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            date = `?sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: `/system-logs${date}`,
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataLogs(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const changeSearchDate = (date: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    const onSearch = () => {
        setLoading(true)
        getLogs(dateStartSearch, dateEndSearch)
    }

    useEffect(() => {
        onSearch()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <LogsTable
                    list={dataLogs}
                    loading={loading}
                    onloadData={onSearch}
                    onDateSearch={(e) => changeSearchDate(e)}
                />
            </div>
        </Fragment>
    )
}

export default Logs
