import React, { Fragment, useEffect, useState } from 'react'
import SendAPI from '../../utils/SendAPI';
import ReportsTable from '../../components/Reports/Table'
import moment from 'moment';

const Reports = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataReports, setDataReports] = useState<any>([])
    const [dataSafe, setDataSafe] = useState<any>([])
    const [dataSummary, setDataSummary] = useState<any>([])

    const [dateStartSearch, setDateStartSearch] = useState<any>()
    const [dateEndSearch, setDateEndSearch] = useState<any>()

    const getReports = async (dateStart: any, dateEnd: any) => {
        let date = ""
        if (dateStart && dateEnd) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            date = `?sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: `/reports${date}`,
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataReports(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }

    const getReportsSummary = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/reports/summary",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataSummary(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    const getSafe = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/safes?status=enable",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataSafe(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }

    const changeSearchDate = (date: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    const onSearch = () => {
        setLoading(true)
        getReports(dateStartSearch, dateEndSearch)
        getReportsSummary()
    }

    useEffect(() => {
        onSearch()
        getSafe()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <ReportsTable
                    list={dataReports}
                    loading={loading}
                    onloadData={onSearch}
                    safeList={dataSafe}
                    summaryList={dataSummary}
                    onDateSearch={(e) => changeSearchDate(e)}
                />
            </div>
        </Fragment>
    )
}

export default Reports
