import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component'
import { Input, Table, Space, DatePicker } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
// import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
import moment from 'moment'


interface LogsTableProps {
    list: any
    loading: boolean
    onloadData: () => void
    onDateSearch: (e: any) => void
}

const LogsTable: FC<LogsTableProps> = ({ list, loading, onloadData, onDateSearch }) => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'

    // const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
    // const [resetPaginationToggle] = useState(false)

    // const numberWithCommas = (num: any) => {
    //     num = num.toFixed(2).toString();
    //     const pattern = /(-?\d+)(\d{3})/;
    //     while (pattern.test(num))
    //         num = num.replace(pattern, "$1,$2");
    //     return num;
    // }

    const [dataOjects, setDataOjects] = useState<any>([])

    const columns = [
        {
            title: '#',
            render: (row: any) => row.serial,
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: 'การทำงาน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.func}
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'รายละเอียด',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.description}
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'ผู้ใช้งาน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.username}
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'ชื่อ - นามสกุล',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.created_user_name}
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
        }
    ]


    useEffect(() => {
        setDataOjects([])
        if (Object.keys(list).length > 0) {
            let filteredItems = (list as any).filter(
                (item: any) => (
                    (item.func && item.func.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.level && item.level.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.username && item.username.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.created_user_name && item.created_user_name.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );

            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        }
    }, [list, filterText, setDataOjects])


    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto d-flex align-items-center">
                            <h2>ประวัติการใช้งาน</h2>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Space
                                direction="horizontal"
                                size={12}>
                                <RangePicker
                                    // defaultValue={[moment().startOf('isoWeek'), moment().endOf('isoWeek')]}
                                    placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                    ranges={{
                                        'วันนี้': [moment(), moment()],
                                        'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                        'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                        'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                        'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    format={dateFormat}
                                    onChange={onDateSearch}
                                />
                                <button type="button" className="btn btn-outline-primary" onClick={onloadData}>ค้นหา</button>
                            </Space>
                        </div>
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " รายการ" }
                    }}
                />
            </ListTableCard>
        </>
    );
}

export default LogsTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
