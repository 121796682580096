import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component'
import CreateData from './Create'
import ViewData from './View'
import { Input, Table, Space, DatePicker, Select, Row, Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
import moment from 'moment'
import AmountCard from '../Dashboard/AmountCard'


interface ReportTableProps {
    list: any
    loading: boolean
    onloadData: () => void
    safeList: any
    summaryList: any
    onDateSearch: (e: any) => void
}

const ReportTable: FC<ReportTableProps> = ({ list, loading, onloadData, safeList, summaryList, onDateSearch }) => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'

    const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
    const [filterSafe, setFilterSafe] = useState('')
    // const [resetPaginationToggle] = useState(false)

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const [reportTotal, setReportTotal] = useState<any>([])
    const [dataOjects, setDataOjects] = useState<any>([])

    const [createDataDrawer, setCreateDataDrawer] = useState(false)
    const [viewDataDrawer, setViewDataDrawer] = useState(false)
    const toggleCreateData = () => setCreateDataDrawer(!createDataDrawer)
    const toggleViewData = () => setViewDataDrawer(!viewDataDrawer)

    const [dataCurrent, setDataCurrent] = useState('')
    const handleEditData = (object: any) => setDataCurrent(object)
    const handleDeleteData = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path: `/reports/${id}`,
                    method: "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    onloadData()
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };

    const columns = [
        {
            title: '#',
            render: (row: any) => row.serial,
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: 'วันที่ทำรายการ',
            render: (row: any) => {
                return (
                    <>
                        {row.date_time.replace(" 00:00:00", "")}
                    </>
                )
            },
        },
        {
            title: 'ตู้เซฟ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.safe_name}
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'รายการ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleEditData(row)
                                toggleViewData()
                            }}
                        >
                            {row.title}
                        </div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.kind === "decrease" ?
                            <div style={{ color: "#ff0000" }}>฿ <strong>-{numberWithCommas(row.amount)}</strong></div>
                            :
                            <div>฿ <strong>{numberWithCommas(row.amount)}</strong></div>
                        }
                        {row.remark &&
                            <small className="text-muted wrap">{`* ${row.remark}`}</small>
                        }
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <Space size={0}>
                        <button className="btn btn-danger btn-sm" onClick={() => { handleDeleteData(row.id) }}>ลบ</button>
                    </Space>
                )
            },
        }
    ]


    const getSafeOption = Object.values(safeList).map((item: any) => {
        return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
    })

    useEffect(() => {
        setDataOjects([])
        if (Object.keys(list).length > 0) {
            let filteredItems = (list as any).filter(
                (item: any) => (
                    (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.amount && item.amount.includes(filterText)) ||
                    (item.remark && item.remark.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );

            if (filterSafe) {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => item.safe_id === filterSafe,
                );
            }

            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        }
    }, [list, filterText, setDataOjects, filterSafe])


    useEffect(() => {
        if (summaryList !== null && Object.keys(summaryList).length > 0) {
            const result: any = {};

            summaryList.forEach((item: any) => {
                const { safe_id, total_amount, kind } = item;

                if (!result[safe_id]) {
                    result[safe_id] = {
                        safe_id,
                        safe_name: item.safe_name,
                        total_amount: 0
                    };
                }

                if (kind === "increase") {
                    result[safe_id].total_amount += total_amount;
                } else if (kind === "decrease") {
                    result[safe_id].total_amount -= total_amount;
                }
            });

            const finalResult = Object.values(result);
            setReportTotal(finalResult)
        }
    }, [summaryList])


    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto d-flex align-items-center">
                            <h2>รายการเงินเข้า-เงินออก</h2>
                        </div>
                        <div className="col-6 col-md-auto ms-md-auto">
                            <Space>
                                <button
                                    className="btn btn-primary mb-3 mb-md-0"
                                    onClick={() => toggleCreateData()}
                                >
                                    <i className="far fa-plus me-3"></i>
                                    <span>เพิ่มเงินเข้า-เงินออก</span>
                                </button>
                            </Space>
                        </div>
                    </div>
                    <div className="site-statistic-demo-card" style={{ marginBottom: '10px' }}>
                        <Row gutter={[16, 16]}>
                            {Object.values(reportTotal).length > 0 && Object.values(reportTotal).map((item: any) => (
                                <Col md={12} sm={24} key={item.safe_id}>
                                    {/* <Card>
                                        <Statistic
                                            title={item.safe_name}
                                            value={numberWithCommas(item.total_amount)}
                                            precision={2}
                                            valueStyle={item.total_amount >= 0 ? { color: '#3f8600' } : { color: '#cf1322' }}
                                            prefix={"฿"}
                                        />
                                    </Card> */}
                                    <AmountCard
                                        colorTheme={item.total_amount >= 0 ? 'linear-gradient(325deg, #3f8600 0%, #3f8600 100%)' : 'linear-gradient(325deg, #cf1322 0%, #cf1322 100%)'}
                                        icon='fas fa-wallet'
                                        iconColorRgb='255, 255, 255'
                                        textColorRgb='255, 255, 255'
                                        buttonText='โอนเงิน'
                                        buttonLink=''
                                        amount={item.total_amount ? numberWithCommas(item.total_amount) : '0.00'}
                                        amountType={item.safe_name}
                                        amountTime={``}
                                        loading={loading}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Space
                                direction="horizontal"
                                size={12}>
                                <RangePicker
                                    // defaultValue={[moment().startOf('isoWeek'), moment().endOf('isoWeek')]}
                                    placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                    ranges={{
                                        'วันนี้': [moment(), moment()],
                                        'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                        'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                        'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                        'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    format={dateFormat}
                                    onChange={onDateSearch}
                                />
                                <button type="button" className="btn btn-outline-primary" onClick={onloadData}>ค้นหา</button>
                            </Space>
                        </div>
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <label>ตู้เซฟ : </label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterSafe}
                                onSelect={(value: any) => {
                                    setFilterSafe(value)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">ทั้งหมด</Select.Option>
                                {getSafeOption}
                            </Select>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " รายการ" }
                    }}
                />
            </ListTableCard>

            <CreateData
                isOpenCreateData={createDataDrawer}
                closeCreateData={() => toggleCreateData()}
                onloadData={() => onloadData()}
                safeList={safeList}
            />

            <ViewData
                listData={dataCurrent as any}
                isOpenViewData={viewDataDrawer}
                closeViewData={() => toggleViewData()}
            />
        </>
    );
}

export default ReportTable

const ListTableCard = styled.div`
    background: #fff;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
