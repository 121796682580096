import React, { Fragment, useEffect, useState } from 'react'
import AmountCard from '../../components/Dashboard/AmountCard'
// import ListCard from '../../components/Dashboard/ListCard'
// import ListTable from '../../components/Dashboard/ListTable'
// import { DatePicker, Space } from 'antd'
// import moment from 'moment'
import SendAPI from '../../utils/SendAPI'
import { Button, Col, Empty, Row } from 'antd'

const Dashboard = () => {
    // const { RangePicker } = DatePicker
    // const dateFormat = 'DD/MM/YYYY'

    // const [dateStartSearch, setDateStartSearch] = useState<any>(moment())
    // const [dateEndSearch, setDateEndSearch] = useState<any>(moment())
    // const [dateLoadData, setDateLoadData] = useState('')

    const { sendAPIWithJWT } = SendAPI();
    const [loadingSummaries, setLoadingSummaries] = useState(true)
    const [BtnloadingSummaries, setBtnLoadingSummaries] = useState(false)

    const [dataSummary, setDataSummary] = useState<any>([])
    const [reportTotal, setReportTotal] = useState<any>([])


    const getReportsSummary = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/reports/summary",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataSummary(dataResponse.data?.data)
            setLoadingSummaries(false)
        }
        setBtnLoadingSummaries(false)
        return dataResponse
    }


    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }
    // const numberWithCommasWithoutDigit = (num: any) => {
    //     num = num.toString();
    //     const pattern = /(-?\d+)(\d{3})/;
    //     while (pattern.test(num))
    //         num = num.replace(pattern, "$1,$2");
    //     return num;
    // }

    const onSearch = () => {
        setLoadingSummaries(true)
        setBtnLoadingSummaries(true)
        getReportsSummary()
    }

    // const changeSearchDate = (date: any, dateString: any) => {
    //     if (date !== null) {
    //         setDateStartSearch(date[0])
    //         setDateEndSearch(date[1])
    //     } else {
    //         setDateStartSearch(null)
    //         setDateEndSearch(null)
    //     }
    // }

    useEffect(() => {
        onSearch()
    }, [])// eslint-disable-line


    useEffect(() => {
        setReportTotal([])
        if (dataSummary !== null && Object.keys(dataSummary).length > 0) {
            const result: any = {};

            dataSummary.forEach((item: any) => {
                const { safe_id, total_amount, kind } = item;

                if (!result[safe_id]) {
                    result[safe_id] = {
                        safe_id,
                        safe_name: item.safe_name,
                        total_amount: 0
                    };
                }

                if (kind === "increase") {
                    result[safe_id].total_amount += total_amount;
                } else if (kind === "decrease") {
                    result[safe_id].total_amount -= total_amount;
                }
            });

            const finalResult = Object.values(result);
            setReportTotal(finalResult)
        }
    }, [dataSummary])


    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <div className="d-lg-flex mb-4">
                    {/* <div className="d-flex mb-3 mb-lg-0">
                        <Space
                            direction="vertical"
                            size={12}
                        >
                            <RangePicker
                                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                defaultValue={[moment(), moment()]}
                                ranges={{
                                    'วันนี้': [moment(), moment()],
                                    'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                    'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                    'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                    'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                }}
                                format={dateFormat}
                                onChange={changeSearchDate}
                            />
                        </Space>
                        <button type="button" className="btn btn-outline-primary ms-3" onClick={onSearch}>ค้นหา</button>
                    </div> */}
                    <Button
                        className="btn btn-primary ms-auto col-12 col-md-3"
                        onClick={onSearch}
                        size='large'
                        loading={BtnloadingSummaries}
                        disabled={BtnloadingSummaries}>อัพเดทยอดเงิน</Button>
                </div>
                {/* <div className="row">
                    {Object.values(reportTotal).map((item: any) => (
                        <div className="col-md-12">
                            <AmountCard
                                colorTheme={item.total_amount >= 0 ? 'linear-gradient(325deg, #3f8600 0%, #3f8600 100%)' : 'linear-gradient(325deg, #cf1322 0%, #cf1322 100%)'}
                                icon='fas fa-wallet'
                                iconColorRgb='255, 255, 255'
                                textColorRgb='255, 255, 255'
                                buttonText='โอนเงิน'
                                buttonLink=''
                                amount={item.total_amount ? numberWithCommas(item.total_amount) : '0.00'}
                                amountType={item.safe_name}
                                amountTime={``}
                                loading={loadingSummaries}
                            />
                            <ListTable list={dataTransfers} loading={loadingTransfers} />
                        </div>
                    ))}
                </div> */}

                <div className="site-statistic-demo-card" style={{ marginBottom: '10px' }}>
                    <Row gutter={[16, 16]}>
                        {Object.values(reportTotal).length > 0 ? Object.values(reportTotal).map((item: any) => (
                            <Col md={12} sm={24} key={item.safe_id}>
                                {/* <Card>
                                        <Statistic
                                            title={item.safe_name}
                                            value={numberWithCommas(item.total_amount)}
                                            precision={2}
                                            valueStyle={item.total_amount >= 0 ? { color: '#3f8600' } : { color: '#cf1322' }}
                                            prefix={"฿"}
                                        />
                                    </Card> */}
                                <AmountCard
                                    colorTheme={item.total_amount >= 0 ? 'linear-gradient(325deg, #3f8600 0%, #3f8600 100%)' : 'linear-gradient(325deg, #cf1322 0%, #cf1322 100%)'}
                                    icon='fas fa-wallet'
                                    iconColorRgb='255, 255, 255'
                                    textColorRgb='255, 255, 255'
                                    buttonText='โอนเงิน'
                                    buttonLink=''
                                    amount={item.total_amount ? numberWithCommas(item.total_amount) : '0.00'}
                                    amountType={item.safe_name}
                                    amountTime={``}
                                    loading={loadingSummaries}
                                />
                            </Col>
                        )) :
                            <div style={{ display: "block", width: "100%" }}>
                                <Empty />
                            </div>
                        }
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard