import React, { Fragment, useEffect, useState } from 'react'
import SafesTable from '../../components/Safes/Table'
// import AccounstMockup from '../../mockup-json/AccountsMockup.json'
import SendAPI from '../../utils/SendAPI'
const Safes = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataSafes, setDataSafes] = useState<any>([])
    const getSafes = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/safes",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataSafes(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    useEffect(() => {
        getSafes()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <SafesTable list={dataSafes} loading={loading} onloadData={getSafes} />
            </div>
        </Fragment>
    )
}

export default Safes